<template>
    <div>
        <br>
        <h3 class="text-dark">Role</h3>
        <br>
        <my-box>

        </my-box>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {

    },
    created() {

    },
};
</script>